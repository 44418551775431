@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.px-100{
  width: 100px;
  height:100px;
}
